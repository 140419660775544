import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { interaction, isWindow } from "../components/Helpers";
import { EdetailPrescribeArikayceStyles } from "../styles/EdetailPrescribeArikayceStyles";
import { AudioTranscript } from "../components/AudioTranscript";
import {
  Container,
  Footnotes,
  Definitions,
  InterstitialInjector,
  TabbingThrough
} from "../components";

import { SectionCarousel } from "../components/Carousels/SectionCarousel";

const EdetailPrescribeArikayce = ({ data }) => {
  const slider_1 = useRef();
  const slider_2 = useRef();
  const [isHCP, setHCP] = useState(false);

  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    tabComponentItems = data.allMarkdownRemark.nodes[0].frontmatter.tabComponent,
    arrowText_1 = data.allMarkdownRemark.nodes[0].frontmatter.arrowText_1[0],
    arrowText_2 = data.allMarkdownRemark.nodes[0].frontmatter.arrowText_2[0],
    audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
    isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
    markdownID = `coverage-content`;

  useEffect(() => {
    if (isWindow) {
      let isHCP = sessionStorage.getItem("hcp");
      if (isHCP !== "true") {
        setHCP(false);
      } else {
        setHCP(true);
      }
    }
  }, [isHCP, setHCP]);

  const [trackWProps, setTrackWProps] = useState('default');
  const [transcriptState, setTranscriptState] = useState(false);

  const pTabsSetTrackWProps = (next) => {
    let trackKey = [
      "default",
      "patient"
    ];
    setTrackWProps(trackKey[next]);
  }
  const pSlides1SetTrackWProps = (next) => {
    let trackKey = [
      "default",
      "commercial"
    ];
    setTrackWProps(trackKey[next]);
  }

  const pInnerTabsSetTrackWProps = (next) => {
    let trackKey = [
      "commercial",
      "government"
    ];
    setTrackWProps(trackKey[next]);
  }
  const pSlides2SetTrackWProps = (next) => {
    let trackKey = [
      "patient",
      "teDay",
      "nebulizer",
      "started"
    ];
    setTrackWProps(trackKey[next]);
  }

  const manipulateDOM = () => {
    const tabs = () => {
      const parentTabs =
        document.querySelectorAll('[data-component="tabs"]').length > 0
          ? document.querySelectorAll('[data-component="tabs"]')
          : false;
      const tabItems =
        document.querySelectorAll("[data-tab-item]").length > 0
          ? document.querySelectorAll("[data-tab-item]")
          : false;
      const tabContent =
        document.querySelectorAll("[data-tab-content]").length > 0
          ? document.querySelectorAll("[data-tab-content]")
          : false;

      if (
        parentTabs &&
        tabItems &&
        tabContent &&
        tabItems.length === tabContent.length
      ) {
        tabItems.forEach((el) => {
          el.addEventListener(interaction, () => {
            if (!el.classList.contains("active")) {
              let contentSelection = el.dataset.tabItem;
              pInnerTabsSetTrackWProps(contentSelection - 1);
              tabItems.forEach((item) => {
                item.classList.remove("active");
              });
              el.classList.add("active");

              tabContent.forEach((la) => {
                if (la.dataset.tabContent === contentSelection) {
                  tabContent.forEach((content) => {
                    content.classList.remove("active");
                  });
                  la.classList.add("active");
                }
              });
            }
          });
        });
        setTimeout(() => {
          let currentSlide = document.querySelector('.slick-slide.slick-active.slick-current div section');
          if (currentSlide) {
            let styles = (isWindow) ? window.getComputedStyle(currentSlide) : null;
            let margin = parseFloat(styles['marginTop']) +
              parseFloat(styles['marginBottom']);
            let absoluteHeight = Math.ceil(currentSlide.offsetHeight + margin);
            document.querySelector('.slick-list').style.cssText = 'height: ' + absoluteHeight + 'px;';
          }
        }, 400);
        setTimeout(() => {
          let currentSlide2 = document.querySelector('section.show.visible[data-tab-index="2"] .slick-list');
          let section = document.querySelector('section#how-to-prescribe')
          let lamiraSlide = document.querySelector('.slick-current[data-index="2"]')

          if (currentSlide2 && window.screen.width < 768) {
            let positionY = section.getBoundingClientRect().y
            currentSlide2.style.cssText = 'height: ' + (positionY + 110) + 'px;';
          }

          if (currentSlide2 && lamiraSlide && window.screen.width < 768){
            currentSlide2.style.cssText = 'height: ' + 755 + 'px;';
          }
        }, 400);

      } else {
        // console.log("Please check your tab markup/markdown structure");
      }
    };

    tabs();
  };

  useEffect(manipulateDOM);

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        isiSnippet={isiSnippet}
        trackWProps={audioTracksWithProps[trackWProps]}
        setTrackWProps={setTrackWProps}
        transcriptState={transcriptState}
        setTranscriptState={setTranscriptState}
      >
        <EdetailPrescribeArikayceStyles id={`main`} className={`wrapper`}>
          <TabbingThrough
           id={`tabbing-through`}
            resetSlides={(tabIndex) => {
              if (tabIndex === 0) {
                slider_1.current.slickGoTo(0);
              }
            }}
            tabs={tabComponentItems}
            setTrackWProps={pTabsSetTrackWProps}
            setTranscriptState={setTranscriptState}
          />
          <SectionCarousel
            arrowText={arrowText_1.copy}
            arrowTextISI={arrowText_1.isiSnippets}
            markdownID={markdownID}
            id={`arikayce-coverage`}
            slider={slider_1}
            slidesYouWantToMake={dataQuery.frontmatter.sectionSlides_1}
            dataOptions={`1`}
            className={`show visible nomargin`}
            setTrackWProps={pSlides1SetTrackWProps}
          />
          <SectionCarousel
            arrowText={arrowText_2.copy}
            arrowTextISI={arrowText_2.isiSnippets}
            markdownID={markdownID}
            id={`how-to-prescribe`}
            slider={slider_2}
            slidesYouWantToMake={dataQuery.frontmatter.sectionSlides_2}
            dataOptions={`2`}
            className={`nomargin`}
            setTrackWProps={pSlides2SetTrackWProps}
          />
          <Footnotes footnotes={dataQuery.frontmatter.footnotes} />
          <Definitions definitions={dataQuery.frontmatter.definitions} />
          <p id={`pi-link`}>Please see full <a href={`https://www.arikayce.com/pdf/full-prescribing-information.pdf`} target={`_blank`} rel={`noreferrer`}>Prescribing Information</a>.</p>
          {/* <References references={dataQuery.frontmatter.references} /> */}
          <AudioTranscript id={`audio-transcript-main`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
          <InterstitialInjector query={dataQuery.frontmatter.interstitials} />
        </EdetailPrescribeArikayceStyles>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {frontmatter: {markdown_id: {eq: "coverage-content"}}}
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          isiSnippet
          footnotes
          references
          cta_next {
            copy
            link
            gtmlabel
          }
          audioTracksWithProps {
            default {
              trackID
              isiContent
              src
              title
              transcript
            }
            commercial {
              trackID
              isiContent
              src
              title
              transcript
            }
            government {
              trackID
              isiContent
              src
              title
              transcript
            }
            patient {
              trackID
              isiContent
              src
              title
              transcript
            }
            teDay {
              trackID
              isiContent
              src
              title
              transcript
            }
            nebulizer {
              trackID
              isiContent
              src
              title
              transcript
            }
            started {
              trackID
              isiContent
              src
              title
              transcript
            }
          }
          injectFootnotes {
            container
            content
          }
		  interstitials {
			container
			trigger
			disclaimer
			setInner
			externalURL
		  }
          sectionSlides_1
          sectionSlides_2
          tabComponent {
            title
            isiSnippets
          }
          arrowText_1 {
            copy
            isiSnippets
          }
          arrowText_2 {
            copy
            isiSnippets
          }
        }
        id
      }
    }
  }
`;

export default EdetailPrescribeArikayce;
